<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">สรุปยอด</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-12">
          <!-- MultipleCard -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0"></h5>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="data_area">ข้อมูล</label>
                  <textarea
                    class="form-control"
                    id="data_area"
                    rows="25"
                    v-model="data"
                  ></textarea>
                </div>
              </div>
              <div
                class="col-md-6 d-flex flex-column justify-content-center align-items-center"
              >
                <div class="row flex-column align-items-center">
                  <h1 v-if="show" class="mb-4">
                    <span class="badge badge-secondary">โอนจ่ายแล้ว</span>
                    : {{ cashOn.toLocaleString() }}
                  </h1>
                  <h1 v-if="show" class="mb-4">
                    <span class="badge badge-info">โอนเข้าร้านหลัก</span>
                    : {{ toShop.toLocaleString() }}
                  </h1>
                  <h1 v-if="show" class="mb-4">
                    <span class="badge badge-success">ยอดปลายทาง</span>
                    : {{ allPrice.toLocaleString() }}
                  </h1>
                  <h1 v-if="show" class="mb-4">
                    <span class="badge badge-primary">ยอดค่าส่ง</span>
                    : {{ deliveryPrice.toLocaleString() }}
                  </h1>
                  <h1 v-if="show" class="mb-4">
                    <span class="badge badge-warning">ยอดส่วนลด</span>
                    : {{ discount.toLocaleString() }}
                  </h1>
                  <div class="mt-4">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="calSummary()"
                    >
                      คำนวณ
                    </button>
                    <button
                      type="button"
                      class="btn btn-warning"
                      @click="resetData()"
                    >
                      รีเซ็ตข้อมูล
                    </button>
                  </div>
                </div>
                <div
                  class="row pt-4 mt-4 w-100 border-top d-flex justify-content-center"
                >
                  <div class="form-group mr-2">
                    <select
                      class="form-control"
                      v-model="branch"
                      @change="getRider()"
                    >
                      <option value="ในเมือง">ในเมือง</option>
                      <option value="ราชภัฏ">ราชภัฏ</option>
                      <option value="วังสะพุง">วังสะพุง</option>
                      <option value="เชียงคาน">เชียงคาน</option>
                      <option value="พัสดุ">พัสดุ</option>
                      <option value="หน้าร้าน">หน้าร้าน</option>
                    </select>
                  </div>
                  <base-input name="dateselect">
                    <template v-slot="{ focus, blur }">
                      <flat-picker
                        @on-open="focus"
                        @on-close="blur"
                        :config="{ allowInput: true }"
                        class="form-control datepicker"
                        v-model="date"
                        @change="getRider()"
                      >
                      </flat-picker>
                    </template>
                  </base-input>
                  <div
                    class="form-group ml-2"
                    v-if="branch !== 'พัสดุ' && branch !== 'หน้าร้าน'"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="ไรเดอร์"
                      v-model="rider"
                    />
                  </div>
                </div>
                <div
                  class="row w-100 d-flex justify-content-center mb-3"
                  v-if="riderHistory.length > 0"
                >
                  <div class="col-12 text-center mb-2">
                    <div class="font-weight-bold">ประวัติ</div>
                  </div>
                  <div class="col-12">
                    <div
                      class="row"
                      v-for="(item, index) in riderHistory"
                      :key="index"
                    >
                      <div class="col-12 text-center">
                        {{ item.rider }}
                        <span
                          ><i
                            class="fa fa-trash-alt text-danger ml-2"
                            style="cursor: pointer"
                            @click="removeRider(item.rider)"
                          ></i
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row w-100 d-flex justify-content-center">
                  <base-button size="sm" type="primary" @click="openNodeModal"
                    >เพิ่มหมายเหตุ</base-button
                  >
                </div>
                <div
                  class="row w-100 mt-3 d-flex justify-content-center"
                  v-if="nodes.length > 0"
                >
                  <div class="col-md-12 text-center">
                    <p class="font-weight-bold">หมายเหตุ</p>
                  </div>
                  <div class="col-12">
                    <div
                      class="row"
                      v-for="(item, index) in nodes"
                      :key="index"
                    >
                      <div class="col-6 text-right">{{ item.text }}</div>
                      <div class="col-6 text-left">
                        {{ item.price }} บาท
                        <span
                          ><i
                            class="fa fa-trash-alt text-danger ml-2"
                            style="cursor: pointer"
                            @click="removeNode(index)"
                          ></i
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="saveSummary()"
                  >
                    บันทึกข้อมูล
                  </button>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <!-- NODE MODAL -->
    <modal v-model:show="nodeModal.show" size="sm">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">หมายเหตุ</h3>
          </div>
        </template>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="nodeText" class="form-control-label">หมายเหตุ</label>
              <input
                name="nodeText"
                id="nodeText"
                type="text"
                class="form-control input-group-alternative"
                v-model="nodeModal.text"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="nodePrice" class="form-control-label">ราคา</label>
              <input
                name="nodePrice"
                id="nodePrice"
                type="text"
                class="form-control input-group-alternative"
                v-model="nodeModal.price"
              />
            </div>
          </div>
        </div>
        <div class="text-right">
          <button
            type="submit"
            class="btn btn-primary"
            @click="submitNodeModal"
          >
            ยืนยัน
          </button>
          <base-button type="danger" @click="closeNodeModal">ปิด</base-button>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import axios from "../services/axios";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import modal from "../components/Modal.vue";

const toast = useToast();

export default {
  name: "SummaryPage",
  components: {
    flatPicker,
    modal,
  },
  data() {
    return {
      data: "",
      branch: "ในเมือง",
      date: this.getDateNow(),
      deliveryPrice: 0,
      allPrice: 0,
      cashOn: 0,
      toShop: 0,
      discount: 0,
      show: false,
      nicPrice: 20,
      rider: "",
      nodeModal: {
        show: false,
        text: "",
        price: "",
      },
      nodes: [],
      riderHistory: [],
    };
  },
  created() {
    if (!this.IsLoggedIn) {
      this.$router.push("/login");
    } else {
      this.getRider();
    }
  },
  methods: {
    removeNode(index) {
      this.nodes = this.nodes.filter((el, i) => i != index);
    },
    closeNodeModal() {
      this.nodeModal.show = false;
    },
    openNodeModal() {
      this.nodeModal.show = true;
    },
    submitNodeModal() {
      this.nodes.push({
        text: this.nodeModal.text,
        price: this.nodeModal.price,
      });

      this.nodeModal.show = false;
      this.nodeModal.text = "";
      this.nodeModal.price = "";
    },
    getRider() {
      if (this.branch !== "หน้าร้าน" && this.branch !== "พัสดุ") {
        axios
          .get(
            `/admin/summary/riderhistory?type=${this.branch}&start=${this.date}&end=${this.date}`
          )
          .then((resp) => {
            this.riderHistory = resp.data.data;
          })
          .catch((err) => {
            if (err?.response) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูลได้"
              );
            }
          });
      } else {
        this.riderHistory = [];
      }
    },
    getDateNow() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
    saveSummary() {
      if (this.data != "" && this.date && this.branch) {
        if (
          this.branch !== "พัสดุ" &&
          this.branch !== "หน้าร้าน" &&
          this.rider === ""
        ) {
          toast.error("กรอกข้อมูลไรเดอร์");
          return;
        }
        swal
          .fire({
            title: `บันทึกยอด ?`,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-primary",
            },
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            icon: "warning",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              let summaryData = [];

              const data = this.data.replaceAll("—", "-").split("-----");
              data.forEach((item) => {
                if (item) {
                  let rItem = item.replaceAll(" : ", ":").replaceAll(": ", ":");

                  let allFine = rItem.split("\n");
                  let productList = [];
                  let nic = 0;

                  allFine.forEach((element) => {
                    if (element.includes(" = ") && element.includes(" | ")) {
                      let name = element.split(" | ")[0];

                      let count = parseFloat(
                        element
                          .split(" | ")[1]
                          .trim()
                          .split(" x")
                          .at(-1)
                          .split(" ")[0]
                          .replace("x", "")
                      );

                      let price = parseFloat(
                        element.split(" | ")[1].split(" = ")[1].replace("฿", "")
                      );

                      const getFlavor = element.split(" | ")[1].split(" x")[0];
                      let flavor = getFlavor.includes(" = ") ? "" : getFlavor;

                      let localNic = 0;
                      if (flavor.includes(" + เพิ่มนิค")) {
                        nic += parseInt(
                          flavor.replaceAll(" ", "").split("+เพิ่มนิค")[1]
                        );
                        localNic = parseInt(
                          flavor.replaceAll(" ", "").split("+เพิ่มนิค")[1]
                        );
                        flavor = flavor.split(" + เพิ่มนิค")[0];
                      }

                      productList.push({
                        name: name,
                        flavor: flavor,
                        count: count,
                        price: price / count - this.nicPrice * localNic,
                      });
                    }
                  });

                  let discount = 0;
                  if (rItem.includes("ยอดส่วนลด")) {
                    discount = parseFloat(
                      rItem.split("ยอดส่วนลด:")[1].split("฿")[0]
                    );
                  }

                  summaryData.push({
                    nic,
                    discount,
                    productList,
                  });
                }
              });

              console.log(summaryData);

              axios
                .post(
                  "/admin/summary",
                  {
                    summaryData,
                    rider: this.rider,
                    branch: this.branch,
                    date: this.date,
                    node: this.nodes,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("AUTH"),
                    },
                  }
                )
                .then((resp) => {
                  if (resp?.data?.status === "success") {
                    toast.success("สำเร็จ");
                    this.nodes = [];
                    this.rider = "";
                    this.getRider();
                  }
                })
                .catch((err) => {
                  if (err?.response) {
                    if (
                      err?.response?.status !== 401 &&
                      err?.response?.status !== 403
                    ) {
                      toast.error(
                        err?.response?.data?.message
                          ? err?.response?.data?.message
                          : "ไม่สามารถทำรายการได้"
                      );
                    } else {
                      this.$store.dispatch("logoutAuth");
                      localStorage.removeItem("AUTH");
                      this.$router.push("/login");
                    }
                  }
                });
            }
          });
      } else {
        toast.error("ใส่ข้อมูลให้ครบถ้วน");
      }
    },
    removeRider(rider) {
      swal
        .fire({
          title: `ลบข้อมูลของไรเดอร์ ?`,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-warning",
            cancelButton: "btn btn-primary",
          },
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          icon: "warning",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .post(`/admin/summary/remove`, {
                type: this.branch,
                start: this.date,
                end: this.date,
                rider: rider,
              })
              .then((resp) => {
                if (resp?.data?.status === "success") {
                  toast.success("ลบข้อมูลสำเร็จ");
                  this.getRider();
                }
              })
              .catch((err) => {
                if (err?.response) {
                  toast.error(
                    err?.response?.data?.message
                      ? err?.response?.data?.message
                      : "ไม่สามารถลบข้อมูลได้"
                  );
                }
              });
          }
        });
    },
    calSummary() {
      if (this.data != "") {
        this.deliveryPrice = 0;
        this.allPrice = 0;
        this.cashOn = 0;
        this.toShop = 0;
        this.discount = 0;

        const data = this.data.replaceAll("—", "-").split("-----");
        data.forEach((item) => {
          if (item) {
            let rItem = item.replaceAll(" : ", ":").replaceAll(": ", ":");

            if (!/จ่ายแล้ว/i.test(rItem)) {
              if (
                rItem.includes("รวมทั้งหมด:") &&
                rItem.includes("ยอดค่าส่ง:")
              ) {
                this.allPrice += parseFloat(
                  rItem.split("รวมทั้งหมด:")[1].split("\n")[0]
                );
                this.deliveryPrice += parseFloat(
                  rItem.split("ยอดค่าส่ง:")[1].split("\n")[0]
                );
              }
              if (rItem.includes("โอนเข้าร้านหลัก:")) {
                let toShop = parseFloat(
                  rItem.split("โอนเข้าร้านหลัก:")[1].split("\n")[0]
                );
                this.toShop += toShop;
                this.allPrice -= toShop;
              }
              if (rItem.includes("ยอดส่วนลด")) {
                let discount = parseFloat(
                  rItem.split("ยอดส่วนลด:")[1].split("฿")[0]
                );
                this.discount += discount;
              }
            } else {
              if (
                rItem.includes("ยอดค่าส่ง:") &&
                rItem.includes("รวมทั้งหมด:")
              ) {
                console.log(rItem);
                this.deliveryPrice += parseFloat(
                  rItem.split("ยอดค่าส่ง:")[1].split("\n")[0]
                );
                this.cashOn += parseFloat(
                  rItem.split("รวมทั้งหมด:")[1].split("\n")[0]
                );
                if (rItem.includes("ยอดส่วนลด")) {
                  let discount = parseFloat(
                    rItem.split("ยอดส่วนลด:")[1].split("฿")[0]
                  );
                  this.discount += discount;
                }
              }
            }
          }
        });

        this.show = true;
      } else {
        toast.error("ใส่ข้อมูล");
      }
    },
    resetData() {
      this.data = "";
      this.deliveryPrice = 0;
      this.allPrice = 0;
      this.cashOn = 0;
      this.toShop = 0;
      this.show = false;
    },
  },
  computed: {
    ...mapGetters(["IsLoggedIn"]),
  },
};
</script>

<style></style>
